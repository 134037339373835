<template>
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-350px"
    data-kt-menu="true"
  >
    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="right"
      data-kt-menu-flip="center, top"
      v-for="(item, index) in dropdownMenuItems"
      :key="index"
    >
      <div class="menu-link px-5">
        <span class="menu-title">{{ item.name }}</span>
        <span class="menu-arrow"></span>
      </div>

      <div class="menu-sub menu-sub-dropdown w-350px py-4">
        <div
          v-for="skill in item.skills"
          :key="skill.id"
          class="menu-item px-3"
        >
          <div
            :class="[
              'menu-link',
              'px-5',
              isSkillSelected(skill.id) && 'disabledLink',
            ]"
            @click="$emit('item-clicked', skill)"
          >
            <span :class="isSkillSelected(skill.id) && 'disabled-link-color'">{{
              skill.name
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "EditProfileModal",
  components: {},
  props: {
    dropdownMenuItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    hasSubmenu: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const submenuItems = ref([]);
    const store = useStore();
    const expertSkills = computed(
      () => store.getters["ExpertModule/getExpertSkills"]
    );
    const openSubmenu = (menuItems) => {
      submenuItems.value = menuItems;
    };

    const isSkillSelected = (skillId) => {
      return expertSkills.value.find(
        (expertSkill) => expertSkill.skills.id === skillId
      );
    };

    return {
      isSkillSelected,
      openSubmenu,
      submenuItems,
    };
  },
});
</script>
<style lang="scss">
.disabledLink {
  pointer-events: none;
  .disabled-link-color {
    color: #c8cad5;
  }
}
</style>
