<template>
  <div
    v-loading="loading"
    id="needExpertId"
    class="bg-white"
    data-kt-drawer="true"
    data-kt-drawer-name="explore"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'90%'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_explore_toggle"
    data-kt-drawer-close="#kt_explore_close"
  >
    <div class="card shadow-none w-100 card-color">
      <div class="card-header" id="kt_explore_header">
        <div class="d-flex justify-content-end align-items-center w-100">
          <el-icon
            id="kt_explore_close"
            class="cursor-pointer close-icon"
            :size="20"
          ><CloseBold
          /></el-icon>
        </div>
      </div>
      <div
        class="card-body"
        style="padding: 40px !important"
        id="kt_explore_body"
      >
        <div>
          <el-row class="expert-header" :gutter="40" v-loading="loading">
            <el-col :span="18">
              <div class="d-flex align-items-center">
                <h2 class="primary-text company-name d-inline-block">
                  YOUR EXPERTS
                </h2>
              </div>
              <div class="d-flex fw-bold mt-4 expert-description">
                <p class="primary-text">
                  A selection of experts that match your need. Check out their profiles and save the the ones
                  <br> that you like the best. You may reach-out to them, once you publish your project.
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row class="mt-8">
            <el-col>
              <el-card class="box-card">
                <div class="card-header strong-match-experts-card-header">
                  <div class="d-flex justify-content-start align-items-center w-100">
                    <h6 class="primary-text company-name d-inline-block">
                      Strong Match Experts
                    </h6>
                  </div>
                </div>
                <div class="row m-8">
                  <div class="col-3 mt-8" v-for="(expert, index) in rocketReachExperts" :key="index">
                    <div class="card box-card m-8 w-70 shadow bg-white rounded"  style="position: relative;">
                      <div class="card-header expert-card-header-border justify-content-center align-items-center">
                        <div class="expert-avatar">
                          <el-avatar :size="100">
                            <img
                              :src="(expert.profile_pic)? expert.profile_pic : 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'"
                            />
                          </el-avatar>
                        </div>
                        <div class="expert-name pt-lg-4">
                          <h6 class="primary-text fw-bold">{{ (expert.name) ? expert.name: '' }}</h6>
                        </div>
                      </div>
                      <div class="row expert-card-subheading">
                        <p>
                          <span class="text-primary">{{ (expert.city) ? expert.city: '' }}</span> <br>
                        {{ (expert.normalized_title) ? expert.normalized_title: '' }}
                        </p>
                      </div>
                      <div class="row">
                        <div class="col-6 card-icons" style="border-right: 1px solid var(--el-card-border-color)">
                          <el-button type="text" class="button">
                           <a :href="(expert.linkedin_url) ? expert.linkedin_url : '#'" target="_blank">
                             <i class="bi bi-linkedin text-primary expert-icon"></i>
                           </a>
                          </el-button>
                        </div>
                        <div class="col-6 card-icons">
                          <el-button type="text" class="button" @click="saveExpert(expert)">
                            <i :class="['text-primary expert-icon', (expert.favorite === true)? 'bi bi-heart-fill' : 'bi bi-heart']"></i>
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { DrawerComponent } from "@/assets/ts/components";
import { CloseBold } from "@element-plus/icons-vue";
import { ElNotification } from "element-plus";
import { getExpertsFromRocketReach, saveRocketReachExpert } from "@/api/founder.api";
export default defineComponent({
  name: "NeedExpertDrawer",
  components: {
    CloseBold
  },
  setup() {
    const drawerShown = ref(false);
    const skill = ref(null);
    const loading = ref(false);
    const rocketReachExperts = ref([]);
    const fetchAllData = async () => {
      try {
        loading.value = true;
        const { data } = await getExpertsFromRocketReach(skill.value);
        rocketReachExperts.value = data.data;
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error fetching data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    const showDrawer = (val) => {
      drawerShown.value = false;
      drawerShown.value = true;
      DrawerComponent.getInstance("needExpertId").show();
      skill.value = val;
      fetchAllData();
    };
    const hideDrawer = () => {
      drawerShown.value = false;
      skill.value = null;
      DrawerComponent.getInstance("needExpertId").hide();
    };
    const saveExpert = async (expert) => {
      try {
        loading.value = true;
        const { data } = await saveRocketReachExpert(expert.id, expert);
        if (data.statusCode === 201) {
          expert.favorite = !expert.favorite;
          let status = "Saved";
          if(expert.favorite === true){
             status = "Saved"
          }else{
             status = "UnSaved"
          }

          ElNotification({
            title: "Success",
            message: "Expert "+ status +" Successfully.",
            type: "success",
          });
        }
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error Saving Expert.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      setTimeout(() => {
        const drawer = DrawerComponent.getInstance("founderProfileDrawer");
        drawer.on("kt.drawer.after.hidden", function () {
          drawerShown.value = false;
          skill.value = null;
        });
      }, 500);
    });
    return {
      showDrawer,
      hideDrawer,
      drawerShown,
      skill,
      loading,
      fetchAllData,
      rocketReachExperts,
      saveExpert,
    }
  }
})

</script>
<style scoped>
.expert-description{
  font-size: 12px;
}
.card-color{
  background-color: aliceblue !important;
}
.strong-match-experts-card-header{
  display: contents !important;
}
.expert-header{
  border-bottom: none !important;
}
.expert-name{
  min-height: 30px;
  padding: 0 2.25rem;
  padding-top: 6rem !important;
}
.expert-card-subheading{
  text-align: center !important;
  border-bottom: 1px solid #eff2f5;
}
.card-icons{
  text-align: center !important;
}
.expert-icon{
  font-size: 24px;
}
.expert-avatar{
  position: absolute;
  bottom: 65%;
}
.expert-card-header-border{
  border-bottom: none !important;
}
</style>