
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { CirclePlus } from "@element-plus/icons-vue";
import {
  getAllProjectNeedsOfProject,
  getAllPriorityLevels,
  createProjectNeed,
  deleteProjectNeed,
  updateProjectNeed,
} from "@/api/founder.api.js";
import { useStore } from "vuex";
import EditProfileModal from "@/expert/components/EditProfileModal.vue";
import EditProfileDropdown from "@/expert/components/EditProfileDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ElNotification } from "element-plus";
import NeedExpertDrawer from "@/founder/components/NeedExpertDrawer.vue";

export default defineComponent({
  name: "NeedsTable",
  components: {
    EditProfileDropdown,
    EditProfileModal,
    CirclePlus,
    NeedExpertDrawer
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const store = useStore();
    const projectNeeds: Ref<any[]> = ref([]);
    const skillsWithGroups = computed(
      () => store.getters["ExpertModule/getSkillsWithGroups"]
    );
    const priorityLevels = ref([]);
    const budgetValues = ref([
      5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000,
      100000, 125000, 150000, 175000, 200000, 225000, 250000, 300000, 350000,
      400000, 450000, 500000, 550000, 600000, 650000, 700000, 750000, 800000,
      850000, 900000, 950000, 1000000,
    ]);
    const budgetValuesInEuroFormat: string[] = [];
    const payload = ref({
      name: null,
      budget: 0,
      startDate: null,
      endDate: null,
      status: null,
      skillId: null,
      priorityLevelId: null,
    });
    const selectedValues = ref({
      skill: "",
      priority: "",
    });
    const loading = ref(false);
    const currentMode = ref("");
    const modes: Ref<string[]> = ref([]);

    onMounted(async () => {
      loading.value = true;
      try {
        for (let i = 0; i < budgetValues.value.length; i++) {
          budgetValuesInEuroFormat.push(budgetValues.value[i].toLocaleString());
        }
        await fetchPriorityLevels();
        await fetchProjectNeeds();
        initModes();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error fetching data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    });

    const initModes = () => {
      for (let i = 0; i < projectNeeds.value.length; i++) {
        modes.value[i] = "save";
      }
    };

    const initializeDropdowns = () => {
      setTimeout(() => {
        MenuComponent.reinitialization();
      }, 0);
    };

    const fetchProjectNeeds = async () => {
      const { data } = await getAllProjectNeedsOfProject();
      projectNeeds.value = data.data;
    };

    const fetchPriorityLevels = async () => {
      const { data } = await getAllPriorityLevels();
      priorityLevels.value = data.data;
    };

    const getPriorityBadgeColor = (priorityLevel) => {
      if (priorityLevel === "Must Have") {
        return "badge-primary";
      } else if (priorityLevel === "Important") {
        return "badge-info";
      } else if (priorityLevel === "Nice to Have") {
        return "badge-success";
      } else {
        return "badge-warning";
      }
    };

    const getProjectStatus = (status) => {
      if (status === 1) {
        return "Complete";
      }
      return "In Progress";
    };

    const onSelectSkill = (skill) => {
      payload.value.skillId = skill.id;
      selectedValues.value.skill = skill.name;
    };

    const onSelectPriorityLevel = (priorityLevel) => {
      payload.value.priorityLevelId = priorityLevel.id;
      selectedValues.value.priority = priorityLevel.name;
    };

    const onSelectBudget = (budgetValue) => {
      payload.value.budget = parseFloat(budgetValue.replace(/,/g, ""));
    };

    const onAddProjectNeed = async () => {
      if (!payload.value.name) {
        ElNotification({
          title: "Error",
          message: "Name is required.",
          type: "error",
        });
        return;
      }
      try {
        loading.value = true;
        await createProjectNeed(payload.value);
        await fetchProjectNeeds();
        await store.dispatch("FounderModule/fetchProjectNeedsStats");
        currentMode.value = "";
        initPayloadWithNullValues();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error adding project need.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const onDeleteProjectNeed = async (projectNeedId) => {
      try {
        loading.value = true;
        await deleteProjectNeed(projectNeedId);
        await fetchProjectNeeds();
        await store.dispatch("FounderModule/fetchProjectNeedsStats");
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error deleting project need.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const onUpdateProjectNeed = async (projectNeedId, index) => {
      if (!payload.value.name) {
        ElNotification({
          title: "Error",
          message: "Name is required.",
          type: "error",
        });
        return;
      }
      try {
        loading.value = true;
        await updateProjectNeed(payload.value, projectNeedId);
        await fetchProjectNeeds();
        await store.dispatch("FounderModule/fetchProjectNeedsStats");
        initPayloadWithNullValues();
        modes.value[index] = "save";
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error updating project need.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const onCreateProjectNeed = async () => {
      if (modes.value.includes("edit")) {
        ElNotification({
          title: "Error",
          message: "Save previous changes first.",
          type: "error",
        });
        return;
      }
      currentMode.value = "create";
      initializeDropdowns();
    };

    const onEditProjectNeed = (index, projectNeed) => {
      if (modes.value.includes("edit") || currentMode.value === "create") {
        ElNotification({
          title: "Error",
          message: "Save previous changes first.",
          type: "error",
        });
        return;
      }
      selectedValues.value.skill = projectNeed.skills
        ? projectNeed.skills.name
        : projectNeed.skills;
      selectedValues.value.priority = projectNeed.priorityLevel
        ? projectNeed.priorityLevel.name
        : projectNeed.priorityLevel;
      payload.value.name = projectNeed.name;
      payload.value.budget = projectNeed.budget;
      payload.value.skillId = projectNeed.skills
        ? projectNeed.skills.id
        : projectNeed.skills;
      payload.value.endDate = projectNeed.endDate;
      payload.value.priorityLevelId = projectNeed.priorityLevel
        ? projectNeed.priorityLevel.id
        : projectNeed.priorityLevel;
      payload.value.startDate = projectNeed.startDate;
      modes.value[index] = "edit";
      initializeDropdowns();
    };

    const onExitEditMode = (index) => {
      initPayloadWithNullValues();
      modes.value[index] = "save";
    };

    const initPayloadWithNullValues = () => {
      Object.keys(payload.value).forEach(
        (property) => (payload.value[property] = null)
      );
    };

    const onExitCreateMode = () => {
      currentMode.value = "";
      initPayloadWithNullValues();
    };

    return {
      projectNeeds,
      skillsWithGroups,
      priorityLevels,
      budgetValues,
      payload,
      selectedValues,
      loading,
      currentMode,
      modes,
      budgetValuesInEuroFormat,
      getPriorityBadgeColor,
      getProjectStatus,
      onSelectSkill,
      onSelectPriorityLevel,
      onSelectBudget,
      onAddProjectNeed,
      onDeleteProjectNeed,
      onCreateProjectNeed,
      onEditProjectNeed,
      onExitEditMode,
      onUpdateProjectNeed,
      onExitCreateMode,
    };
  },
});
