<template>
  <div>
    <h1 class="primary-text">NEEDS</h1>
    <p class="primary-text paragraph fw-bold">
      This section helps you to create all sorts of needs and will form basis of
      your smart-capital funding campaign on 7Founders.
    </p>
    <NeedsStats class="mt-10" />
    <NeedsTable class="mt-10" />
  </div>
</template>

<script>
import NeedsStats from "../components/NeedsStats";
import NeedsTable from "../components/NeedsTable";
export default {
  name: "Needs",
  components: { NeedsTable, NeedsStats },
};
</script>

<style scoped lang="scss">
.paragraph {
  width: 40%;
}
</style>
