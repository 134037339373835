<template>
  <el-row gutter="20" v-loading="loading">
    <el-col :span="6">
      <h3 class="primary-text fw-boldest">
        {{ projectNeedsStats.totalNeeds ? projectNeedsStats.totalNeeds : 0 }}
      </h3>
      <span class="primary-text fw-bold">NEEDS ADDED IN TOTAL</span>
    </el-col>
    <el-col :span="6">
      <h3 class="primary-text fw-boldest">
        {{
          projectNeedsStats.totalNeedsBudget
            ? `€${Number(projectNeedsStats.totalNeedsBudget).toLocaleString()}`
            : "€0"
        }}
      </h3>
      <span class="primary-text fw-bold">TOTAL VALUE OF NEEDS</span>
    </el-col>
    <el-col :span="6">
      <h3 class="primary-text fw-boldest">
        {{
          projectNeedsStats.mustHaveNeedsBudget
            ? `€${Number(
                projectNeedsStats.mustHaveNeedsBudget
              ).toLocaleString()}`
            : "€0"
        }}
      </h3>
      <span class="primary-text fw-bold">VALUE OF MUST HAVES</span>
    </el-col>
    <e-col :span="6">
      <h3 class="primary-text fw-boldest">
        {{
          projectNeedsStats.importantNeedsBudget
            ? `€${Number(
                projectNeedsStats.importantNeedsBudget
              ).toLocaleString()}`
            : "€0"
        }}
      </h3>
      <span class="primary-text fw-bold">VALUE OF IMPORTANT NEEDS</span>
    </e-col>
  </el-row>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";

export default {
  name: "NeedsStats",
  setup() {
    const store = useStore();
    const loading = ref(false);
    const projectNeedsStats = computed(
      () => store.getters["FounderModule/getProjectNeedsStats"]
    );

    onMounted(async () => {
      loading.value = true;
      try {
        await store.dispatch("FounderModule/fetchProjectNeedsStats");
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Unable to fetch stats.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    });

    return { projectNeedsStats, loading };
  },
};
</script>

<style scoped></style>
